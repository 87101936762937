import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "im-index"
  }, [_c("a-card", {
    attrs: {
      bordered: false
    }
  }, [_c("a-form", {
    attrs: {
      form: _vm.createForm,
      layout: "inline"
    }
  }, [_c("FormItems", {
    attrs: {
      searchKeys: _vm.searchKeys,
      getDataWithKey: _vm.getDataWithKey
    },
    scopedSlots: _vm._u([{
      key: "button",
      fn: function fn() {
        return [_c("a-button", {
          attrs: {
            size: "large",
            type: "primary"
          },
          on: {
            click: _vm.handleSearch
          }
        }, [_vm._v("查询")]), _c("a-button", {
          staticStyle: {
            "margin-left": "20px",
            border: "0",
            background: "#f5f5f5"
          },
          attrs: {
            size: "large"
          },
          on: {
            click: _vm.handleReset
          }
        }, [_vm._v("重置")])];
      },
      proxy: true
    }])
  })], 1)], 1), _c("a-card", {
    staticStyle: {
      "margin-top": "20px"
    },
    attrs: {
      borderd: false
    }
  }, [_c("a-table", {
    attrs: {
      pagination: false,
      dataSource: _vm.dataSource,
      columns: _vm.columns,
      rowKey: function rowKey(it, i) {
        return it.id;
      },
      loading: _vm.loading,
      scroll: {
        x: "100%"
      }
    },
    on: {
      change: _vm.tabChangeHandler
    },
    scopedSlots: _vm._u([{
      key: "headimgurl",
      fn: function fn(headimgurl, row) {
        return [_c("div", {
          staticStyle: {
            position: "relative"
          }
        }, [_c("img", {
          staticStyle: {
            height: "100px",
            width: "100px",
            "object-fit": "cover",
            "border-radius": "6px"
          },
          attrs: {
            src: headimgurl
          },
          on: {
            click: function click($event) {
              return _vm.preview(headimgurl);
            }
          }
        }), row.icon ? _c("a-avatar", {
          staticClass: "channel-icon",
          attrs: {
            size: "small",
            src: row.icon
          }
        }) : _vm._e()], 1)];
      }
    }, {
      key: "ownername",
      fn: function fn(ownername, row) {
        return [_c("a", {
          staticStyle: {
            color: "#1890ff",
            "word-break": "break-all"
          },
          attrs: {
            target: "_blank",
            href: "/user/detail?uid=".concat(row.ownerid)
          }
        }, [_vm._v(_vm._s(ownername))])];
      }
    }, {
      key: "recommendswitch",
      fn: function fn(recommendswitch, row) {
        return [recommendswitch ? _c("a-icon", {
          staticStyle: {
            color: "yellowgreen",
            "font-size": "20px"
          },
          attrs: {
            type: "check"
          }
        }) : _c("a-icon", {
          staticStyle: {
            "font-size": "20px",
            color: "#ff4d4f"
          },
          attrs: {
            type: "close"
          }
        })];
      }
    }, {
      key: "actions",
      fn: function fn(actions, row) {
        return [_c("div", [_c("a", {
          on: {
            click: function click($event) {
              $event.stopPropagation();
              return _vm.handleButton("view", row);
            }
          }
        }, [_vm._v("查看")]), _c("a", {
          staticStyle: {
            margin: "0 16px"
          },
          on: {
            click: function click($event) {
              $event.stopPropagation();
              return _vm.handleButton("edit", row);
            }
          }
        }, [_vm._v("管理")]), _c("a-button", {
          directives: [{
            name: "hasPermission",
            rawName: "v-hasPermission",
            value: ["im:group:addrobot"],
            expression: "['im:group:addrobot']"
          }],
          staticStyle: {
            padding: "0",
            "min-width": "fit-content"
          },
          style: row.isshow ? "color: #3270f5;" : "color: rgba(0,0,0,.25);",
          attrs: {
            type: "link",
            disabled: !row.isshow
          },
          on: {
            click: function click($event) {
              $event.stopPropagation();
              return _vm.handleButton("add", row);
            }
          }
        }, [_c("span", {
          staticStyle: {
            "font-weight": "600"
          }
        }, [_vm._v("加人")])])], 1)];
      }
    }])
  }), _c("pagination", {
    attrs: {
      total: _vm.total,
      page: _vm.page,
      size: _vm.size,
      onPageSizeChange: _vm.onPageSizeChange,
      onShowSizeChange: _vm.onPageSizeChange
    },
    on: {
      "update:page": function updatePage($event) {
        _vm.page = $event;
      },
      "update:size": function updateSize($event) {
        _vm.size = $event;
      }
    }
  })], 1), _c("PreviewPic", {
    attrs: {
      isShowPic: _vm.previewVisible,
      previewImage: _vm.previewImage
    },
    on: {
      closePreviewpic: function closePreviewpic($event) {
        _vm.previewVisible = false;
      }
    }
  }), _c("a-modal", {
    attrs: {
      title: "群聊加人操作",
      width: 360,
      centered: "",
      dialogClass: "im-dialog-center",
      cancelText: "取消",
      okText: "提交",
      confirmLoading: _vm.isloading
    },
    on: {
      cancel: _vm.cancelHandler,
      ok: _vm.submit
    },
    model: {
      value: _vm.visible,
      callback: function callback($$v) {
        _vm.visible = $$v;
      },
      expression: "visible"
    }
  }, [_c("a-form-model", {
    ref: "addForm",
    attrs: {
      labelCol: {
        span: 6
      },
      model: _vm.addForm
    }
  }, [_c("a-form-model-item", {
    attrs: {
      label: "群名称"
    }
  }, [_c("span", [_vm._v(_vm._s(_vm.robotItem.name))])]), _c("a-form-model-item", {
    staticStyle: {
      "line-height": "1.2"
    },
    attrs: {
      label: "新增人数",
      prop: "num",
      rules: [{
        required: true,
        message: "请输入新增人数"
      }]
    }
  }, [_c("a-input-number", {
    staticStyle: {
      width: "160px"
    },
    attrs: {
      type: "number",
      max: 99,
      min: 1,
      precision: 0,
      placeholder: "请输入新增人数"
    },
    model: {
      value: _vm.addForm.num,
      callback: function callback($$v) {
        _vm.$set(_vm.addForm, "num", $$v);
      },
      expression: "addForm.num"
    }
  }), _c("p", {
    staticStyle: {
      "line-height": "20px",
      "margin-top": "8px",
      color: "#333"
    }
  }, [_vm._v("单次最少添加1人，最多添加99人，该操作会在10分钟内完成")])], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };